<template>
    <TextInput
        ref="input"
        v-model="localModelValue"
        :type="isVisible ? 'text' : 'password'"
        name="password"
        autocomplete="password"
        autofocus
        :placeholder="$t('labels.search_for_a_product')"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
    >
        <template v-for="(_, slot) in $slots" #[slot]="scope">
            <slot :name="slot" v-bind="scope || {}" />
        </template>
        <template #rightIcon>
            <span class="cursor-pointer" @click="toggleVisibility">
                <EyeSlashIcon v-if="isVisible" class="input-icon" />
                <EyeIcon v-else class="input-icon" />
            </span>
        </template>
    </TextInput>
</template>

<script>
import TextInput from './TextInput.vue';
import EyeIcon from '@assets/icons/Eye.svg?component';
import EyeSlashIcon from '@assets/icons/EyeSlash.svg?component';

export default {
    name: 'PasswordInput',
    components: {
        TextInput,
        EyeIcon,
        EyeSlashIcon,
    },
    inheritAttrs: false,
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            localModelValue: this.modelValue,
            isVisible: false,
        };
    },
    watch: {
        modelValue(newValue) {
            this.localModelValue = newValue;
        },
        localModelValue(newValue) {
            this.$emit('update:modelValue', newValue);
        },
    },
    methods: {
        focus() {
            if (!this.$refs.input) {
                return;
            }

            this.$refs.input.focus();
        },
        toggleVisibility() {
            this.isVisible = !this.isVisible;
        },
    },
};
</script>
